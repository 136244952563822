<template>
<span
    class="btn btn-icon btn-average"
    :class="[ color, `btn-${size}` ]"
>
    <div class="average-icon w-100 text-nowrap mt-2">
        <slot name="display-value" />
    </div>
    <div class="text-muted text-nowrap">
        <slot name="sub-value" />
    </div>
</span>
</template>

<script lang="ts">

export default {
    name: 'StudentCourseAverage',
    props: {
        color: {
            type: String,
            required: false,
            default: 'Blue',
        },
        hexColor: {
            type: String,
            required: false,
            default: '#007bff',
        },
        size: { // xs sm md lg xl
            type: String,
            required: false,
            default: 'md',
        },
    },
    computed: {

    },
};
</script>

<style scoped>
div.text-muted {
    font-size: 0.8rem;
}

.btn-average {
    display: inline !important;
    max-width: 4rem;
}
</style>

<style>
span.btn-md div.average-icon {
    font-size: 1.3rem;
    max-width: 4rem;
}
span.btn-xl div.average-icon {
    font-size: 1.8rem;
    max-width: 4rem;
}
</style>
<style scoped src='../css/colors.scss' lang="scss" />
