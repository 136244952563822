var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "btn btn-icon btn-average",
      class: [_vm.color, `btn-${_vm.size}`],
    },
    [
      _c(
        "div",
        { staticClass: "average-icon w-100 text-nowrap mt-2" },
        [_vm._t("display-value")],
        2
      ),
      _c(
        "div",
        { staticClass: "text-muted text-nowrap" },
        [_vm._t("sub-value")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }